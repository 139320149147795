<template>
  <div class="TaskJob">
    <el-row>
      <el-select v-model="versions" placeholder="请选择节点版本" class="mr_20">
        <el-option label="versions2&2.5" :value="2"> </el-option>
        <el-option label="versions3" :value="3"> </el-option>
      </el-select>

      <el-button @click="findTaskAlarmFun" type="primary" style="margin-left: 10px">查询</el-button>
    </el-row>
    <MyTable :dataSource="dataSource" :columns="columns" :loading="loading" :total="total" index border stripe :currentPage="currentPage" :pageSize="pageSize" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" @handleClick="handleClick"></MyTable>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'
import { findTaskAlarm } from '@/api/Alarm.js'
const columns = [
  {
    prop: 'pointName',
    label: '节点名称',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'createTime',
    label: '报警时间',
    type: 'text',
    align: 'center',
  },

  {
    prop: 'dataBatch',
    label: '数据批次',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'warningThreshold',
    label: '预警阈值',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'alarmThreshold',
    label: '报警阈值',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'alarmValue',
    label: '当前报警值',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'safeState',
    label: '报警级别',
    type: 'icon',
    align: 'center',
  },
  {
    prop: 'handleTime',
    label: '处理时间',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'processingConditions',
    label: '处理结果',
    type: 'result_text',
    align: 'center',
  },
]

const dataSource = []

export default {
  name: 'AlarmList',
  components: { MyTable },
  watch: {
    num() {
      console.log(this.num)
      this.findTaskAlarmFun()
    },
  },
  data() {
    return {
      versions: 2,
      num: 1,
      projectName: null,
      currentPage: 0,
      pageSize: 10,
      total: null,
      loading: true,
      dataSource,
      columns,
      stateId: 0,
      pkTaskId: null,
      hostId: null,
      hostList: [],
      disabled: false,
      value1: '报警',
      value2: 'F',
      pkAlarmId: null,
    }
  },

  methods: {
    async findTaskAlarmFun() {
      this.loading = true
      const { data: res } = await findTaskAlarm(this.$route.params.projectId - 0, 1, this.currentPage, this.pageSize, this.versions)
      console.log(res.data.list)
      this.total = res.data.total
      res.data.list.forEach((item) => {
        item.pointName = item.point.pointName
      })
      this.dataSource = res.data.list
      setTimeout(() => {
        this.loading = false
      }, 300)
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.findTaskAlarmFun()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.findTaskAlarmFun()
    },
    handleClick(row, type) {
      switch (type) {
        case 'edit':
          console.log(row, '修改')
          this.pkAlarmId = row.pkAlarmId
          this.dialogVisibleDispose = true

          break
      }
    },
    handleSelectItem() {},
    handleClose() {
      this.dialogVisibleDispose = false
    },
  },
  created() {
    this.findTaskAlarmFun()
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.TaskJob {
  padding: 10px;
  width: calc(100% - 20px);
  .el-row {
    min-width: 1600px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .mr_10 {
    margin-right: 10px;
  }
  .el-input {
    width: 300px;
  }
}

.addTask ::v-deep .el-dialog {
  width: 800px;
  height: 600px;
}
.upload ::v-deep .el-dialog {
  width: 500px;
  height: 800px;
}
.upState ::v-deep .el-dialog {
  width: 350px !important;
  height: 240px;
}

.TaskInfo ::v-deep .el-dialog {
  width: 1360px;
  height: 1000px;

  .el-dialog__body {
    padding: 0 25px;
  }
}

.TaskJob {
  ::v-deep .el-message-box__content {
    height: 200px !important;
  }
}

.el-message-box__message {
  height: 200px;
}

.disposeAlarm {
  ::v-deep .el-dialog {
    padding: 0;
    width: 300px;
    height: 280px;
  }
}
</style>
