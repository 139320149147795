<template>
  <div class="TaskJob">
    <el-scrollbar>
      <el-row>
        <el-select v-model="versions" placeholder="请选择节点版本" class="mr_20">
          <el-option label="versions2&2.5" :value="2"> </el-option>
          <el-option label="versions3" :value="3"> </el-option>
        </el-select>

        <el-button type="primary" @click="findTaskAlarmFun" style="margin-left: 10px">当前报警查询</el-button>
        <el-button type="primary" @click="abuttingFun">警情上报</el-button>
        <el-button type="primary" @click="clearAbutListFun">误报处理</el-button>
        <el-button type="primary" @click="uploadSubwayAlarm" :disabled="pointId.length === 0" pointId>地铁报警数据上报</el-button>
      </el-row>
      <MyTable :dataSource="dataSource" :columns="columns" :loading="loading" selection="true" border stripe @handleSelectionChange="handleSelectionChange"></MyTable>
    </el-scrollbar>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'
import { getDocking, clearAbutList, abutting } from '@/api/Alarm.js'
import { sendAlarmBySubway } from '@/api/subway.js'
const columns = [
  {
    prop: 'pointName',
    label: '节点名称',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'createTime',
    label: '报警时间',
    type: 'text',
    align: 'center',
  },

  {
    prop: 'dataBatch',
    label: '数据批次',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'warningThreshold',
    label: '预警阈值',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'alarmThreshold',
    label: '报警阈值',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'alarmValue',
    label: '当前报警值',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'safeState',
    label: '报警级别',
    type: 'icon',
    align: 'center',
  },
]

const dataSource = []

export default {
  name: 'AlarmList',
  components: { MyTable },
  watch: {
    num() {
      console.log(this.num)
      this.findTaskAlarmFun()
    },
  },
  data() {
    return {
      versions: 2,
      num: 1,
      projectName: null,

      loading: false,
      dataSource,
      columns,
      stateId: 0,
      pkTaskId: null,
      hostId: null,
      hostList: [],
      disabled: false,
      value1: '报警',
      value2: 'F',
      pkAlarmId: null,
      ids: [],
      pointId: [],
    }
  },

  methods: {
    async findTaskAlarmFun() {
      this.loading = true
      const { data: res } = await getDocking(this.$route.params.projectId - 0, this.versions)
      console.log('获取', res.data)
      const list = []
      res.data.forEach((item) => {
        if (item) {
          item.pointName = item.point.pointName
          list.push(item)
        }
      })
      this.dataSource = list
      setTimeout(() => {
        this.loading = false
      }, 300)
    },
    async clearAbutListFun() {
      const { data: res } = await clearAbutList(this.ids.join(), this.versions)

      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.findTaskAlarmFun()
    },
    async abuttingFun() {
      const { data: res } = await abutting(this.ids.join(), this.versions)

      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.findTaskAlarmFun()
    },

    async uploadSubwayAlarm() {
      const { data: res } = await sendAlarmBySubway({ pointIds: this.pointId.join() })
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.findTaskAlarmFun()
    },

    handleSelectionChange(e) {
      this.ids = []
      this.pointId = []
      e.forEach((item) => {
        this.ids.push(item.id)
        this.pointId.push(item.fkPointId)
      })
    },
    handleClose() {
      this.dialogVisibleDispose = false
    },
  },
  created() {
    // this.findTaskAlarmFun()
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.TaskJob {
  padding: 10px;
  padding-top: 0;
  width: calc(100% - 20px);
  height: calc(100% - 10px);
  overflow: hidden;
  .el-scrollbar {
    height: 100%;
  }

  .el-scrollbar ::v-deep.el-scrollbar__wrap {
    overflow: scroll;
    height: 100% !important;
    overflow-x: hidden !important;
  }

  .el-row {
    min-width: 1600px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .mr_10 {
    margin-right: 10px;
  }
  .el-input {
    width: 300px;
  }
}
.addTask ::v-deep .el-dialog {
  width: 800px;
  height: 600px;
}
.upload ::v-deep .el-dialog {
  width: 500px;
  height: 800px;
}
.upState ::v-deep .el-dialog {
  width: 350px !important;
  height: 240px;
}

.TaskInfo ::v-deep .el-dialog {
  width: 1360px;
  height: 1000px;

  .el-dialog__body {
    padding: 0 25px;
  }
}

.TaskJob {
  ::v-deep .el-message-box__content {
    height: 200px !important;
  }
}

.el-message-box__message {
  height: 200px;
}

.disposeAlarm {
  ::v-deep .el-dialog {
    padding: 0;
    width: 300px;
    height: 280px;
  }
}
</style>
