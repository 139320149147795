<template>
  <div class="chart">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="警情判断" name="first"> <Pending ref="first"></Pending></el-tab-pane>
      <el-tab-pane label="警情上报" name="thirdly">
        <Abutting ref="thirdly"></Abutting>
      </el-tab-pane>

      <el-tab-pane label="已处理" name="second"><Finish ref="second"></Finish> </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Pending from './Pending.vue'
import Finish from './Finish.vue'
import Abutting from './Abutting.vue'
export default {
  components: {
    Pending,
    Finish,
    Abutting,
  },
  data() {
    return {
      activeName: 'first',
    }
  },
  methods: {
    findTaskAlarmFun() {
      console.log('调用index方法,同时刷新处理和未处理数据')

      this.$nextTick(() => {
        this.$refs.first.findTaskAlarmFun()
        this.$refs.second.findTaskAlarmFun()
      })
    },
    handleClick() {},
  },
  created() {},
  mounted() {},
}
</script>

<style scoped lang="scss">
.chart {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  padding: 10px;
  // overflow: hidden;
  // .el-tabs {
  //   height: 100% !important;
  //   ::v-deep .el-tabs__content {
  //     height: calc(100% - 60px) !important;
  //     .el-tab-pane {
  //       height: 100%;
  //     }
  //   }
  // }
}
</style>
